import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';

import { url_host_rest } from '../credentials/base';

const MainReactObj = (props) => {
    let token = window.location.pathname.split('/')[2];

    const [state_password, set_password] = useState('');
    const [state_check, set_check] = useState('');

    useEffect(() => {
        axios({
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            url: url_host_rest + '/redirect/pr2',
            data: {
                token: token,
            },
        })
        .then((res) => {
            if (res && res.data && res.data.message === 'COMPLETED') {
                localStorage.setItem('token', res.data.token);
                return;
            }
            alert('密码重设期限已过');
            localStorage.removeItem('token');
            window.location.href = '/backops';
        });
    }, []);

    const handle_click = (data) => {
        switch (data.switch) {
            case 'password-submit':
                data.e.preventDefault();
                password_submit();
            break;

            default:
        };
    };

    const password_submit = () => {
        // validation
        if (state_password.length < 5) {
            alert('密碼必須最少5個字元');
            return;
        }
        if (state_check !== state_password) {
            alert('密碼確認 與 新密碼 不一樣');
            return;
        }

        axios({
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
            url: url_host_rest + '/user/password_reset_02',
            data: {
                password: state_password,
            },
        })
        .then((res) => {
            if (res && res.data && res.data.message === 'COMPLETED') {
                alert('重設密碼成功！請重新登入');
            }
            else {
                alert('發生錯誤');
            }
            localStorage.removeItem('token');
            window.location.href = '/backops';
            return;
        })
        .catch((err) => {
            alert(err.response.data.message);
        });
    };

    return (
        <Main>
            <Logo src={'/img/qujieyou_logo.png'} />
            <LogoSub src={'/img/qujieyou_logo_b.png'} />
            <LogoSub src={'/img/qujieyou_logo_c.png'} />
            <div style={{paddingTop:'1rem', marginLeft:'auto', marginRight:'auto', width:'30%'}}>～密码重设～</div>

            <form onSubmit={(e) => handle_click({switch:'password-submit', e:e})}>
                <InputWrapper>
                    <Input value={state_password} onChange={(e) => set_password(e.target.value)} type='password' placeholder='请输入 新密码' />
                </InputWrapper>

                <InputWrapper>
                    <Input value={state_check} onChange={(e) => set_check(e.target.value)} type='password' placeholder='再输入一次 新密码' />
                </InputWrapper>

                <div style={{marginTop:'3rem'}}></div>

                <Button type='submit'>确定</Button>
            </form>

            <div style={{paddingTop:'3rem', paddingBottom:'3rem'}}>&nbsp;</div>
        </Main>
    );
};

export default MainReactObj;

const Button = styled.button`
    border-radius: 40px;
    box-sizing: border-box;
    background-color: #BC7646;
    border: 0;
    font-size: 1.2rem;
    padding: 1rem 4rem 1rem 4rem;
    margin-top: 1rem;
    color: #FFFFFF;
`;

const Input = styled.input`
    height: 3rem;
    width: 90%;
    border-radius: 10px;
    border: 0;
    margin-top: 2vmax;
    // text-align: right;
    padding: 0.8rem 1rem;
    box-sizing: border-box;
    font-size: 1rem;
`;

const InputWrapper = styled.div`
    position: relative;

    span {
        position: absolute;
        top: 1.9rem;
        left: 2.3rem;
        color: #838383;
    }

    @media (min-width: 500px) {
        span {
            top: 2.5rem;
            left: 2.5rem;
        }    
    }
`;

const Logo = styled.img`
    display: block;
    padding-top: 7rem;
    margin-left: auto;
    margin-right: auto;
    width: 30%;
`;

const LogoSub = styled.img`
    display: block;
    padding-top: 1rem;
    margin-left: auto;
    margin-right: auto;
    width: 30%;
`;

const Main = styled.div`
    text-align: center;
    background-color: #F7F4F2;
    // height: 100vh;

    @media (min-width: 500px) {
        max-width: 500px;
        margin: auto;
    }
`;
