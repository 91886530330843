import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsis } from '@fortawesome/free-solid-svg-icons'

import Backdrop from './Backdrop';

const SpinnerBackdrop = (props) => {
    return (
        <React.Fragment>
            <Backdrop show={props.show_backdrop} />
            <Logo src={'/img/qujieyou_logo.png'} />
            <Text>{props.text}&nbsp;&nbsp;<FontAwesomeIcon icon={faEllipsis} beat size='lg' /></Text>
        </React.Fragment>
    );
};

export default SpinnerBackdrop;

const Text = styled.div`
    z-index: 1302;
    color: white;
    position: fixed;
    width: 200px;
    text-align: center;
    top: 35%;
    left: calc(50% - 100px);
    margin-top: 115px;
    font-size: 1.3rem;
`;

const Logo = styled.img`
    width: 100px;
    z-index: 1302;
    position: fixed;
    top: 35%;
    left: calc(50% - 50px);
`;
