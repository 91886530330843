import React from 'react';
import { styled } from 'styled-components';

const MainReactObj = () => {

    return (
        <Main>
            {/* <Background src={'/img/terms_bottom.png'} /> */}
            <div style={{paddingTop:'3rem', paddingBottom:'3rem', zIndex:3, position:'relative'}}>
                <div style={{fontSize:'1.5rem'}}>用户使用协议</div>
                <div style={{textAlign:'left', width:'90%', margin:'auto', marginTop:'2rem'}}>欢迎来到有间杂货店！</div>
                <div style={{textAlign:'left', width:'90%', margin:'auto', marginTop:'0.5rem'}}>为了更好地为您提供服务，请您仔细阅读并充分理解本《用户使用协议》及各条款内容，特别是免除或者限制有间杂货店责任的条款，以及与您的个人信息收集和使用等有关的条款，并自主选择接受或不接受。如您不具有完全的民事行为能力（包括但不限于未满18周岁），请您在法定监护人陪同下仔细阅读并充分理解本协议，并征得法定监护人的同意后下载/使用有间杂货店平台及服务。</div>
                <div style={{textAlign:'left', width:'90%', margin:'auto', marginTop:'0.5rem'}}>如果您对本协议的任何条款有异议，您<b>有权选择不</b>注册、登录、下载、安装、使用（以下统称“<b>使用</b>”）有间杂货店平台及服务。无论您以何种方式使用有间杂货店提供的任何服务，即视为您已充分理解本协议并作为本协议的一方当事人完全接受本协议的全部条款并受其约束。不论您是否为有间杂货店的注册用户，有间杂货店有权在任何时间不经通知地修改本协议。如您不接受修改内容，您<b>有权选择停止使用</b>有间杂货店提供的服务。</div>
                
                <div style={{textAlign:'left', width:'90%', margin:'auto', marginTop:'0.5rem'}}><b>一、 本协议适用范围</b></div>
                <table style={{textAlign:'left', width:'93%', margin:'auto', marginTop:'0.5rem'}}>
                    <tbody>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}>1.1</td>
                            <td>本协议是您与上海有间杂货店青年职业能力发展中心及其相关方（统称“<b>有间杂货店</b>”）之间关于您使用有间杂货店平台及服务所订立的协议。“用户”或“您”均指有间杂货店平台和服务的使用人。</td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}>1.2</td>
                            <td>本协议项下的“有间杂货店平台”包括但不限于有间杂货店网站、软件、应用、APP、小程序、服务器、存储空间、其他载体。</td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}>1.3</td>
                            <td>本协议项下的“有间杂货店服务”是指有间杂货店通过有间杂货店平台向用户提供的包括但不限于交流、互动、信息、媒体、存储、互联网增值和广告等产品、软件及服务。</td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}>1.4</td>
                            <td>您在使用有间杂货店某一特定服务时，该服务可能会另有已经发布或后续陆续发布的单独的协议、规则等（以下统称“<b>单独协议或规则</b>”）。上述内容一经发布，即成为本协议不可分割的组成部分。</td>
                        </tr>
                    </tbody>
                </table>

                <div style={{textAlign:'left', width:'90%', margin:'auto', marginTop:'0.5rem'}}><b>二、 使用限制</b></div>
                <table style={{textAlign:'left', width:'93%', margin:'auto', marginTop:'0.5rem'}}>
                    <tbody>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}>2.1</td>
                            <td>您仅有权以合法使用为目的而使用有间杂货店平台和服务。</td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}>2.2</td>
                            <td>除非得到有间杂货店事先书面同意，您不得以任何形式改编、复制、传播、再版、下载、爬虫或销售有间杂货店平台和服务及相关的知识产权或内容。</td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}>2.3</td>
                            <td>本协议未明示授权的其他一切权利仍由有间杂货店保留，您在行使该些权利时须另行获得有间杂货店的许可。</td>
                        </tr>
                    </tbody>
                </table>

                <div style={{textAlign:'left', width:'90%', margin:'auto', marginTop:'0.5rem'}}><b>三、 用户注册及账户使用规范</b></div>
                <table style={{textAlign:'left', width:'93%', margin:'auto', marginTop:'0.5rem'}}>
                    <tbody>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}>3.1</td>
                            <td>为使您更好地使用有间杂货店平台及服务，保障您的账户安全，某些功能和服务项目，需要您提供真实的身份信息实名注册并登陆后方可使用。</td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}>3.2</td>
                            <td>您理解并承诺，您所设置的帐号不得违反国家法律法规及有间杂货店的相关规则。您的帐户名称、头像等注册信息及其他信息中不得出现违法和侵权信息，未经他人许可不得用他人名义（包括但不限于冒用他人或机构的姓名、名称、字号、商标等足以让人引起混淆的方式）开设账户。</td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}>3.3</td>
                            <td>您在有间杂货店的注册帐号所有权归有间杂货店所有，您完成注册手续后仅享有该帐号的使用权。您的帐号仅限于您本人使用，未经有间杂货店书面同意，不得以任何形式赠与、借用、出租、转让、售卖或以其他方式许可他人使用该帐号。</td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}>3.4</td>
                            <td>请不要将您的帐户信息透露给任何其他人。您自行负责您帐户的保密和安全，并对您的帐户发生的或通过您的帐户发生的一切活动负全部责任。当您使用完毕后，应安全退出。因您保管不善导致的遭受盗号或密码失窃，责任由您自行承担。</td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}>3.5</td>
                            <td>在注册、使用和管理账户时，您应保证填写信息的真实性及合法性，请您在注册、使用和管理账户时使用合法、真实、准确、有效的身份证明材料及必要信息（包括您的姓名、联系电话、联系地址、电子邮箱等）。若您提交的材料或信息违法违规或者有间杂货店有理由怀疑为错误、不实的资料，则您可能无法使用有间杂货店平台和服务，或在使用过程中部分功能受到限制。有间杂货店有权不定期对您的身份进行确认，在确认期间，若您的账户未能通过确认，有间杂货店保留注销或冻结您的账户的权利。</td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}>3.6</td>
                            <td>为了更好的为您提供服务及确保您的帐户安全，您同意并授权有间杂货店可根据您提供的手机号码、身份证号码等信息，向全国公民身份号码查询服务中心、电信运营商、金融服务机构等机构或单位发起用户身份真实性、用户征信记录、用户手机号码有效性状态等情况的查询。</td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}>3.7</td>
                            <td>因其他人恶意攻击或您自身原因或不可抗力而导致帐号被盗、丢失， 有间杂货店不承担任何责任。</td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}>3.8</td>
                            <td>若您发现有间杂货店平台存在任何缺陷或漏洞，须立即书面通知有间杂货店。在任何情况下，您不得利用前述缺陷或漏洞从事违法违规或有违诚实信用、公平原则等行为。</td>
                        </tr>
                    </tbody>
                </table>

                <div style={{textAlign:'left', width:'90%', margin:'auto', marginTop:'0.5rem'}}><b>四、 用户行为规范</b></div>
                <table style={{textAlign:'left', width:'93%', margin:'auto', marginTop:'0.5rem'}}>
                    <tbody>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}>4.1</td>
                            <td>除非法律允许或者经有间杂货店事先许可，您使用有间杂货店平台及服务不得具有下列行为：</td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}></td>
                            <td>
                                <table><tbody><tr>
                                    <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'3rem'}}>4.1.1</td>
                                    <td>使用未经有间杂货店授权或许可的任何插件、外挂或第三方工具对有间杂货店服务的正常运行进行干扰、破坏或施加其他影响。</td>
                                </tr></tbody></table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}></td>
                            <td>
                                <table><tbody><tr>
                                    <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'3rem'}}>4.1.2</td>
                                    <td>使用有间杂货店平台和服务制作、复制、发布、传播或转载下列内容和信息：</td>
                                </tr></tbody></table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}></td>
                            <td>
                                <table><tbody><tr>
                                    <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'3rem'}}></td>
                                    <td>
                                        <table><tbody>
                                            <tr>
                                                <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'1.5rem'}}>1) </td>
                                                <td>反对宪法所确定的基本原则的；</td>
                                            </tr>
                                            <tr>
                                                <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'1.5rem'}}>2) </td>
                                                <td>危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</td>
                                            </tr>
                                            <tr>
                                                <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'1.5rem'}}>3) </td>
                                                <td>损害国家荣誉和利益的；</td>
                                            </tr>
                                            <tr>
                                                <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'1.5rem'}}>4) </td>
                                                <td>煽动民族仇恨、民族歧视，破坏民族团结的；</td>
                                            </tr>
                                            <tr>
                                                <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'1.5rem'}}>5) </td>
                                                <td>破坏国家宗教政策，宣扬邪教和封建迷信的；</td>
                                            </tr>
                                            <tr>
                                                <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'1.5rem'}}>6) </td>
                                                <td>散布谣言，扰乱社会秩序，破坏社会稳定的；</td>
                                            </tr>
                                            <tr>
                                                <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'1.5rem'}}>7) </td>
                                                <td>散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</td>
                                            </tr>
                                            <tr>
                                                <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'1.5rem'}}>8) </td>
                                                <td>侮辱或者诽谤他人，侵害他人合法权益的；</td>
                                            </tr>
                                            <tr>
                                                <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'1.5rem'}}>9) </td>
                                                <td>含有法律、行政法规禁止的其他内容的。</td>
                                            </tr>
                                        </tbody></table>
                                    </td>
                                </tr></tbody></table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}></td>
                            <td>
                                <table><tbody><tr>
                                    <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'3rem'}}>4.1.3</td>
                                    <td>利用或针对有间杂货店平台或服务进行任何危害计算机网络安全的行为，包括但不限于：</td>
                                </tr></tbody></table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}></td>
                            <td>
                                <table><tbody><tr>
                                    <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'3rem'}}></td>
                                    <td>
                                        <table><tbody>
                                            <tr>
                                                <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'1.5rem'}}>1) </td>
                                                <td>企图探查、扫描、测试有间杂货店平台的漏洞、缺陷或实施其他破坏网络安全的行为；</td>
                                            </tr>
                                            <tr>
                                                <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'1.5rem'}}>2) </td>
                                                <td>企图干涉、破坏有间杂货店平台的正常运行，故意传播恶意程序或病毒以及其他破坏干扰正常网络信息服务的行为；</td>
                                            </tr>
                                            <tr>
                                                <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'1.5rem'}}>3) </td>
                                                <td>使用未经许可的数据或进入未经许可的服务器/帐户；</td>
                                            </tr>
                                            <tr>
                                                <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'1.5rem'}}>4) </td>
                                                <td>未经允许进入公众计算机网络或者他人计算机系统并删除、修改、增加存储信息；</td>
                                            </tr>
                                        </tbody></table>
                                    </td>
                                </tr></tbody></table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}></td>
                            <td>
                                <table><tbody><tr>
                                    <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'3rem'}}>4.1.4</td>
                                    <td>对有间杂货店平台及服务进行反向工程、反向编译或者以其他方式破解本软件的源代码。</td>
                                </tr></tbody></table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}></td>
                            <td>
                                <table><tbody><tr>
                                    <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'3rem'}}>4.1.5</td>
                                    <td>违反法律法规、本协议、有间杂货店的相关规则和侵犯他人合法权益的其他行为。</td>
                                </tr></tbody></table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}>4.2</td>
                            <td>您同意遵守有间杂货店平台和服务的使用规范、协议或制度。</td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}>4.3</td>
                            <td>如您不具有完全的民事行为能力（包括但不限于未满18周岁），您使用有间杂货店平台及服务应获得监护人同意，并在监护人监护、指导下使用，并由您及监护人依照法律规定和本协议约定承担由此而导致的一切后果。</td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}>4.4</td>
                            <td>未经有间杂货店书面许可，任何人或机构，不得自行或授权、允许、协助其他人或机构复制、读取、抓取或采用有间杂货店平台及服务中的任何信息内容。</td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}>4.5</td>
                            <td>如果用户的服务被终止，有间杂货店有权在有间杂货店平台永久地删除用户的数据且不承担任何责任。服务中止或终止后，有间杂货店无义务向用户返还数据。</td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}>4.6</td>
                            <td>有间杂货店有权对用户使用有间杂货店平台及服务的情况进行审查和监督，如有间杂货店有理由认为用户在使用有间杂货店平台及服务时违反上述任何规定，有间杂货店（包括其授权方）有权在任何时候进行独立判断并采取手段予以删除、屏蔽、断开链接，同时，有间杂货店有权视用户的行为性质，采取包括但不限于限制、冻结或终止有间杂货店账号使用，暂停或终止服务，追究相关责任等措施。</td>
                        </tr>
                    </tbody>
                </table>

                <div style={{textAlign:'left', width:'90%', margin:'auto', marginTop:'0.5rem'}}><b>五、 隐私权政策</b></div>
                <table style={{textAlign:'left', width:'93%', margin:'auto', marginTop:'0.5rem'}}>
                    <tbody>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}>5.1</td>
                            <td>有间杂货店非常重视用户的隐私和个人信息保护。您在使用有间杂货店服务时，有间杂货店可能会收集和使用您的相关信息。我们通过本隐私权政策向您说明有间杂货店在您使用有间杂货店服务时如何收集、使用、保存、共享和转让这些信息，以及有间杂货店为您提供的访问、更新、删除和保护这些信息的方式。您使用或在有间杂货店更新本隐私权政策后继续使用有间杂货店服务，即意味着您同意本隐私权政策（含更新及调整）内容，并且同意有间杂货店按照本隐私权政策收集、使用、保存和共享您的相关信息。</td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}>5.2</td>
                            <td>有间杂货店收集和使用用户个人信息</td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}></td>
                            <td>
                                <table><tbody><tr>
                                    <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'3rem'}}>5.2.1</td>
                                    <td>用户个人信息，是指以电子或者其他方式记录的用户姓名、出生日期、身份证件号码、住址、电话号码、账号和密码等能够单独或者与其他信息结合识别用户的信息以及用户使用服务的时间、地点等信息。在您进行注册、登录、使用软件或服务等操作时，有间杂货店可能会收集您的各种信息，包括但不限于：您的姓名、性别、生日、地址、电话号码、电子邮箱、兴趣爱好、面部特征、网络身份标识信息、IP地址、通讯录、个人上网记录、个人常用设备信息、个人位置信息等。</td>
                                </tr></tbody></table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}></td>
                            <td>
                                <table><tbody><tr>
                                    <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'3rem'}}>5.2.2</td>
                                    <td>在您访问有间杂货店平台、使用有间杂货店服务时，我们可能还收集和汇总网站、软件打开情况、页面访问情况、链接和点击、使用时间、访问和使用日志等信息，收集这些信息可能涉及到记录用户的地理位置、IP地址、设备型号、操作系统、浏览器、语言等。</td>
                                </tr></tbody></table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}></td>
                            <td>
                                <table><tbody><tr>
                                    <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'3rem'}}>5.2.3</td>
                                    <td>我们可能从第三方获取您授权共享的账户信息，使您可以通过第三方账户直接登录并使用我们的产品与/或服务。我们会将依据与第三方的约定、对个人信息来源的合法性进行确认后，在符合相关法律和法规规定的前提下，使用您的这些个人信息。</td>
                                </tr></tbody></table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}>5.3</td>
                            <td>有间杂货店收集以上个人信息，主要用于：</td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}></td>
                            <td>
                                <table><tbody><tr>
                                    <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'3rem'}}>5.3.1</td>
                                    <td>信息展示和搜索及实现用户评论等功能。</td>
                                </tr></tbody></table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}></td>
                            <td>
                                <table><tbody><tr>
                                    <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'3rem'}}>5.3.2</td>
                                    <td>验证您的身份，保障您的帐号安全等安全防范功能。</td>
                                </tr></tbody></table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}></td>
                            <td>
                                <table><tbody><tr>
                                    <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'3rem'}}>5.3.3</td>
                                    <td>为了您能够更便捷地使用有间杂货店提供的服务，或使用您的个人信息帮助您解决使用过程中遇到的问题，根据您的爱好与兴趣，提供更适合您的内容等。</td>
                                </tr></tbody></table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}></td>
                            <td>
                                <table><tbody><tr>
                                    <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'3rem'}}>5.3.4</td>
                                    <td>用于数据分析、研究、用户调查等，帮助有间杂货店更加了解您使用有间杂货店服务，以便改善当前的软件或服务，并设计、提供新服务和产品。</td>
                                </tr></tbody></table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}></td>
                            <td>
                                <table><tbody><tr>
                                    <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'3rem'}}>5.3.5</td>
                                    <td>有间杂货店可能会收集您的浏览信息、您的兴趣爱好进行数据分析以形成用户画像，用来将您感兴趣的商品或服务信息展示给您；或在您搜索时向您展示您可能希望找到的信息。</td>
                                </tr></tbody></table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}>5.4</td>
                            <td>Cookies和同类技术的使用</td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}></td>
                            <td>
                                <table><tbody><tr>
                                    <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'3rem'}}>5.4.1</td>
                                    <td>有间杂货店可能通过Cookies和网络Beacon等技术收集、使用和存储您的信息。Cookies和网络Beacon可以理解为放置在有间杂货店的您的个人信息和访问行为的记录，只能被设置它们的服务器读取。通过Cookies和网络Beacon，有间杂货店能够更好、更快地为您提供服务。</td>
                                </tr></tbody></table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}></td>
                            <td>
                                <table><tbody><tr>
                                    <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'3rem'}}>5.4.2</td>
                                    <td>通过Cookies和网络Beacon等技术获得的有关信息，亦适用本协议。</td>
                                </tr></tbody></table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}></td>
                            <td>
                                <table><tbody><tr>
                                    <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'3rem'}}>5.4.3</td>
                                    <td>有间杂货店平台或服务上可能会有广告客户或其他合作伙伴放置的Cookies或网络Beacon，这些Cookies或网络Beacon亦可能会收集与您相关的信息。这些第三方Cookies和网络Beacon不受本隐私政策约束，而是受到其自身的个人信息保护声明约束，有间杂货店不对第三方的Cookies或网络Beacon承担责任。</td>
                                </tr></tbody></table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}></td>
                            <td>
                                <table><tbody><tr>
                                    <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'3rem'}}>5.4.4</td>
                                    <td>您可根据自己的偏好管理或删除 Cookies和网络Beacon。您可以清除计算机上保存的所有 Cookies和网络Beacon，大部分网络浏览器会自动接受Cookies和网络Beacon，但您通常可根据自己的需要来修改浏览器的设置以拒绝 Cookies和网络Beacon；另外，您也可以清除软件内保存的所有Cookies和网络Beacon。</td>
                                </tr></tbody></table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}>5.5</td>
                            <td>有些信息是使用有间杂货店服务所必需的，但大多数其他信息的提供由您决定，您可以自行决定是否提供这些信息。</td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}>5.6</td>
                            <td>部分有间杂货店服务让您可以公开分享您的相关信息。您选择公开分享的信息，可能被他人收集或使用，对于此类情况，有间杂货店不承担责任。</td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}>5.7</td>
                            <td>有间杂货店平台和服务中可能包含第三方网站链接、产品、软件或服务，这些第三方可能收集用户个人信息，您应仔细阅读相关条款。有间杂货店规则仅适用于有间杂货店收集的任何信息，对于第三方使用由您提供的信息，有间杂货店不承担责任。</td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}>5.8</td>
                            <td>有间杂货店不会将用户个人信息转移或披露给任何非关联的第三方，除非存在如下一种或多种情形：</td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}></td>
                            <td>
                                <table><tbody><tr>
                                    <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'3rem'}}>5.8.1</td>
                                    <td>与国家安全、国防安全有关；</td>
                                </tr></tbody></table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}></td>
                            <td>
                                <table><tbody><tr>
                                    <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'3rem'}}>5.8.2</td>
                                    <td>与公共安全、公共卫生、重大公共利益有关；</td>
                                </tr></tbody></table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}></td>
                            <td>
                                <table><tbody><tr>
                                    <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'3rem'}}>5.8.3</td>
                                    <td>与犯罪侦查、起诉、审判和判决执行等有关；</td>
                                </tr></tbody></table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}></td>
                            <td>
                                <table><tbody><tr>
                                    <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'3rem'}}>5.8.4</td>
                                    <td>相关法律法规或司法、行政等国家机关要求；</td>
                                </tr></tbody></table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}></td>
                            <td>
                                <table><tbody><tr>
                                    <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'3rem'}}>5.8.5</td>
                                    <td>有间杂货店为提供您要求的服务所必需；</td>
                                </tr></tbody></table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}></td>
                            <td>
                                <table><tbody><tr>
                                    <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'3rem'}}>5.8.6</td>
                                    <td>获得用户或法定监护人的授权；</td>
                                </tr></tbody></table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}></td>
                            <td>
                                <table><tbody><tr>
                                    <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'3rem'}}>5.8.7</td>
                                    <td>为完成合并、分立、收购或资产转让；</td>
                                </tr></tbody></table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}></td>
                            <td>
                                <table><tbody><tr>
                                    <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'3rem'}}>5.8.8</td>
                                    <td>为维护有间杂货店自身合法权益而向用户提起诉讼或仲裁；</td>
                                </tr></tbody></table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}></td>
                            <td>
                                <table><tbody><tr>
                                    <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'3rem'}}>5.8.9</td>
                                    <td>其他合理情形或法律法规许可的情形。</td>
                                </tr></tbody></table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}>5.9</td>
                            <td>账户信息查询、更正、删除</td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}></td>
                            <td>
                                <table><tbody><tr>
                                    <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'3rem'}}>5.9.1</td>
                                    <td>除法律法规另行规定外，您有权随时访问和更正您的个人信息。</td>
                                </tr></tbody></table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}></td>
                            <td>
                                <table><tbody><tr>
                                    <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'3rem'}}>5.9.2</td>
                                    <td>您有权随时删除您的个人信息。</td>
                                </tr></tbody></table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}></td>
                            <td>
                                <table><tbody><tr>
                                    <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'3rem'}}>5.9.3</td>
                                    <td>您有权随时注销您的个人信息。</td>
                                </tr></tbody></table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}></td>
                            <td>
                                <table><tbody><tr>
                                    <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'3rem'}}>5.9.4</td>
                                    <td>您可以登陆有间杂货店平台自行查询、更正、删除您的个人信息。</td>
                                </tr></tbody></table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}>5.10</td>
                            <td>账户注销</td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}></td>
                            <td>
                                <table><tbody><tr>
                                    <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'3rem'}}>5.10.1</td>
                                    <td>您可以在有间杂货店平台中直接申请注销账户。您注销账户后，有间杂货店有权停止为您提供全部或部分服务，并依据您的要求，删除您的个人信息，但法律法规另有规定除外。</td>
                                </tr></tbody></table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}></td>
                            <td>
                                <table><tbody><tr>
                                    <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'3rem'}}>5.10.2</td>
                                    <td>注销账户后，您将放弃以下权益和资产：</td>
                                </tr></tbody></table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}></td>
                            <td>
                                <table><tbody><tr>
                                    <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'3rem'}}></td>
                                    <td>
                                        <table><tbody>
                                            <tr>
                                                <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'1.5rem'}}>1) </td>
                                                <td>账户、会员权益、虚拟资产且无法恢复；</td>
                                            </tr>
                                            <tr>
                                                <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'1.5rem'}}>2) </td>
                                                <td>无法使用账户原验证手机或邮箱登陆有间杂货店平台；</td>
                                            </tr>
                                            <tr>
                                                <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'1.5rem'}}>3) </td>
                                                <td>解除与第三方授权或绑定关系。</td>
                                            </tr>
                                        </tbody></table>
                                    </td>
                                </tr></tbody></table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}></td>
                            <td>
                                <table><tbody><tr>
                                    <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'3rem'}}>5.10.3</td>
                                    <td>您注销账户的行为可能会给您带来诸多不便，请您在操作之前，确保您的账户无任何纠纷，并自行备份您账户相关的所有信息、数据和资料。注销账户后，有间杂货店有权根据相关法律规定保存相关交易记录。</td>
                                </tr></tbody></table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}></td>
                            <td>
                                <table><tbody><tr>
                                    <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'3rem'}}>5.10.4</td>
                                    <td>账户注销需满足如下条件：</td>
                                </tr></tbody></table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}></td>
                            <td>
                                <table><tbody><tr>
                                    <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'3rem'}}></td>
                                    <td>
                                        <table><tbody>
                                            <tr>
                                                <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'1.5rem'}}>1) </td>
                                                <td>在最近一个月内，账户没有进行更改密码、更改绑定信息等敏感操作；</td>
                                            </tr>
                                            <tr>
                                                <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'1.5rem'}}>2) </td>
                                                <td>自愿放弃账户在有间杂货店平台中的资产和虚拟权益、无欠款；</td>
                                            </tr>
                                            <tr>
                                                <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'1.5rem'}}>3) </td>
                                                <td>账户无任何纠纷，包括投诉举报或被投诉举报；</td>
                                            </tr>
                                            <tr>
                                                <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'1.5rem'}}>4) </td>
                                                <td>已妥善处理了账户下的所有问题；</td>
                                            </tr>
                                            <tr>
                                                <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'1.5rem'}}>5) </td>
                                                <td>账户已经解除与其他网站、其他APP的授权登录或绑定关系。</td>
                                            </tr>
                                        </tbody></table>
                                    </td>
                                </tr></tbody></table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}></td>
                            <td>
                                <table><tbody><tr>
                                    <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'3rem'}}>5.10.5</td>
                                    <td>在您的账户注销期间，如果您的账户涉及争议纠纷，包括但不限于投诉、举报、诉讼、仲裁、国家有权机关调查等，有间杂货店有权自行终止本账户的注销而无需另行得到您的同意。</td>
                                </tr></tbody></table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}></td>
                            <td>
                                <table><tbody><tr>
                                    <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'3rem'}}>5.10.6</td>
                                    <td>注销账户，您将无法再使用本账户，也将无法找回您本账户中及与账户相关的任何内容或信息。</td>
                                </tr></tbody></table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}></td>
                            <td>
                                <table><tbody><tr>
                                    <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'3rem'}}>5.10.7</td>
                                    <td>您理解并同意，有间杂货店无法协助您重新恢复前述服务。请您在提交注销申请前，务必先了解您须解绑的其他相关账户信息。</td>
                                </tr></tbody></table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}></td>
                            <td>
                                <table><tbody><tr>
                                    <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'3rem'}}>5.10.8</td>
                                    <td>注销您的账户并不代表您账户注销前的账户行为和相关责任得到豁免或减轻。</td>
                                </tr></tbody></table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}>5.11</td>
                            <td>有间杂货店非常重视个人信息安全，并采取合理可行的措施，保护您的个人信息。但请您谅解，由于技术的限制以及风险防范的局限，即便我们已经尽量加强安全措施，也无法始终保证信息绝对安全。</td>
                        </tr>
                    </tbody>
                </table>

                <div style={{textAlign:'left', width:'90%', margin:'auto', marginTop:'0.5rem'}}><b>六、 知识产权</b></div>
                <table style={{textAlign:'left', width:'93%', margin:'auto', marginTop:'0.5rem'}}>
                    <tbody>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}>6.1</td>
                            <td>有间杂货店平台和服务中包含的文本、图片、图形、视频、版面设计、排版方式、标识、创意、模式等均受著作权法、商标法、专利法、反不正当竞争法等法律法规的保护。未经有间杂货店及其他相关权利人书面同意，上述内容均不得被任何主体在任何平台被直接或间接转载、链接、发布、使用、衍生其他作品或被用于其他任何商业目的。</td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}>6.2</td>
                            <td>有间杂货店保留对相关网站、软件和服务中包含的文本、图片、图形、视频、版面设计、排版方式、标识、创意、模式等内容进行编辑、调整、删除等权利。</td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}>6.3</td>
                            <td>在有间杂货店发布、上传的内容，您应保证您为著作权人或已取得完整合法授权，并且该内容不会侵犯任何第三方的合法权益。如果第三方提出关于著作权或其他合法权益的异议，有间杂货店有权根据实际情况删除相关的内容，追究您的法律责任并要求您赔偿有间杂货店遭受的所有损失。</td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}>6.4</td>
                            <td>有间杂货店有权但无义务对您上传、发布的内容进行审核，有权根据相关证据结合《侵权责任法》、《信息网络传播权保护条例》等法律法规及有间杂货店规则对侵权信息进行处理。</td>
                        </tr>
                    </tbody>
                </table>

                <div style={{textAlign:'left', width:'90%', margin:'auto', marginTop:'0.5rem'}}><b>七、 广告推广</b></div>
                <table style={{textAlign:'left', width:'93%', margin:'auto', marginTop:'0.5rem'}}>
                    <tbody>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}>7.1</td>
                            <td>您使用有间杂货店服务过程中，充分理解并同意：有间杂货店服务中可能包括有间杂货店针对个人或企业等发布的广告、推广等信息和服务。</td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}>7.2</td>
                            <td>您应当自行判断任何一则广告或推广信息的真实性和可靠性并为自己的判断行为负责。</td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}>7.3</td>
                            <td>如您不同意任何一则广告或推广，您有权选择关闭该信息或停止使用有间杂货店服务。</td>
                        </tr>
                    </tbody>
                </table>

                <div style={{textAlign:'left', width:'90%', margin:'auto', marginTop:'0.5rem'}}><b>八、 免责声明</b></div>
                <table style={{textAlign:'left', width:'93%', margin:'auto', marginTop:'0.5rem'}}>
                    <tbody>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}>8.1</td>
                            <td>有间杂货店按“现状”和“可得到”的状态向您提供服务，有间杂货店对其服务不作任何适用性、持续性及适用于某一特定用途等保证。</td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}>8.2</td>
                            <td>有间杂货店有权在提前公告通知后，修改、更新、中断或终止有间杂货店服务，无需向用户负责或承担任何赔偿责任。</td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}>8.3</td>
                            <td>有间杂货店不对用户在有间杂货店服务中发布、上传、传播或转载的内容的正确性进行保证。</td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}>8.4</td>
                            <td>对于任何涉及财产的信息、广告或推广等信息，用户应谨慎对待并自行进行判断，基于前述原因产生的权益或损失等，由用户自行享有和承担，有间杂货店不承担任何直接或间接责任。</td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}>8.5</td>
                            <td>对于因不可抗力或有间杂货店不能控制的其他原因（包括但不限于：病毒、木马、恶意程序攻击、电力故障、第三方服务瑕疵等）造成的有间杂货店服务中断、终止或其它问题，有间杂货店不承担任何责任。</td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}>8.6</td>
                            <td>有间杂货店平台可能含有第三方内容或与第三方网站的链接。这些内容与链接仅为您提供方便，并仅供您参考。对于第三方的任何内容和网站，包括但不限于其准确性、主题、质量或及时性，有间杂货店既无法控制，也不为其承担任何责任，您须对该等内容或链接自行判断并承担风险，不得向有间杂货店主张任何权利、权益。</td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}>8.7</td>
                            <td>在任何情况下，有间杂货店仅对其故意或重大过失行为承担责任。</td>
                        </tr>
                    </tbody>
                </table>

                <div style={{textAlign:'left', width:'90%', margin:'auto', marginTop:'0.5rem'}}><b>九、 适用法律及争议解决</b></div>
                <table style={{textAlign:'left', width:'93%', margin:'auto', marginTop:'0.5rem'}}>
                    <tbody>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}>9.1</td>
                            <td>本协议适用中华人民共和国大陆法律。</td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}>9.2</td>
                            <td>因本协议产生的或与本协议有关的任何争议，应友好协商解决。未能协商解决的，应提交至上海仲裁委员会按照仲裁提交时该会届时有效的仲裁规则进行仲裁。</td>
                        </tr>
                    </tbody>
                </table>

                <div style={{textAlign:'left', width:'90%', margin:'auto', marginTop:'0.5rem'}}><b>十、 协议生效及修改</b></div>
                <table style={{textAlign:'left', width:'93%', margin:'auto', marginTop:'0.5rem'}}>
                    <tbody>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}>10.1</td>
                            <td>无论您以何种方式使用有间杂货店提供的任何服务，即视为您已充分理解本协议并作为本协议的一方当事人完全接受本协议的全部条款并受其约束。</td>
                        </tr>
                        <tr>
                            <td style={{verticalAlign:'top', paddingTop:'0.2rem', minWidth:'2rem'}}>10.2</td>
                            <td>基于法律法规变化、业务发展及运营调整等原因，有间杂货店有权对本协议作出修改或变更。一旦有间杂货店对本协议进行调整，有间杂货店将修改后的协议予以公布，该公布行为即视为有间杂货店已经将修改后的内容通知用户。如果用户不同意有间杂货店对本协议所做的任何修改，用户有权并应当停止使用有间杂货店服务。如果用户继续使用有间杂货店服务，则视为用户完全接受有间杂货店对本协议的修改并受其约束。</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </Main>
    );
};

export default MainReactObj;

const Background = styled.img`
    z-index: 2;
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;

    @media (min-width: 500px) {
        max-width: 500px;
        margin: auto;
    }
`;

const Main = styled.div`
    z-index: 1;
    position: relative;
    text-align: center;
    background-color: #FFFFFF;
    // height: 100vh;

    @media (min-width: 500px) {
        max-width: 500px;
        margin: auto;
    }
`;
