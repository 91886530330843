import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquarePlus, faSquareMinus } from '@fortawesome/free-regular-svg-icons'
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';

const MainReactObj = (props) => {
    let unique_key = 0;

    const [state_response_expanded, set_response_expanded] = useState(false);
    const [state_response_mail_uuid_top, set_response_mail_uuid_top] = useState(null);
    const [state_response_source, set_response_source] = useState(null);
    const [state_response_try_id_selected, set_response_try_id_selected] = useState(null);
    const [state_response_try_content, set_response_try_content] = useState('');

    const handle_click = (data) => {
        switch (data.switch) {
            case 'expand':
                set_response_expanded(true);
            break;

            case 'collapse':
                set_response_expanded(false);
            break;

            case 'edit-response-on':
                set_response_mail_uuid_top(data.mail_uuid_top);
                set_response_source(data.source);
                set_response_try_id_selected(data.response_try_id);
                set_response_try_content(data.content);
            break;

            case 'edit-response-off':
                set_response_mail_uuid_top(null);
                set_response_source(null);
                set_response_try_id_selected(null);
                set_response_try_content('');
            break;

            default:
        }
    };

    return (
        <div>
            <div style={{marginTop:'0.5rem', marginBottom:'0.5rem', display:'flex', justifyContent:'space-between'}}>
                <div style={{width:'33%', textAlign:'left', marginLeft:'1rem'}}><Button onClick={(e) => props.handle_click({switch:'view-list'})} variant="secondary" size="sm">返回</Button></div>
                <div style={{width:'33%', textAlign:'center', fontSize:'1.2rem', verticalAlign:'middle'}}>回信编辑</div>
                <div style={{width:'33%'}}></div>
            </div>

            <Table striped bordered>
                <tbody>
                    <tr>
                        <td>
                            <FontAwesomeIcon icon={faSquarePlus} size='lg' onClick={(e) => handle_click({switch:'expand'})} style={{display:state_response_expanded ? 'none' : 'block'}} />
                            <FontAwesomeIcon icon={faSquareMinus} size='lg' onClick={(e) => handle_click({switch:'collapse'})} style={{display:state_response_expanded ? 'block' : 'none'}} />
                        </td>
                        <td style={{textAlign:'left'}}>
                            <div>{state_response_expanded ? '' : '展开信件对话'}</div>
                            {props.data_chain.map((item) => {
                                return (
                                    <div style={{display:state_response_expanded ? 'block' : 'none'}} key={unique_key++}>
                                        <div style={{textAlign:'left'}}>({item.mail_created_by == 0 ? '爷爷' : '孩子'}) {item.mail_created_on_local}</div>
                                        <div style={{textAlign:'left', marginLeft:'0.75rem', marginBottom:'1rem', whiteSpace:'pre-wrap'}}>{item.content}</div>
                                    </div>
                                );
                            })}
                        </td>
                    </tr>

                    {state_response_try_id_selected 
                    ? (
                        <tr>
                            <td></td>
                            <td style={{textAlign:'left'}}>
                                <textarea onChange={(e) => set_response_try_content(e.target.value)} rows={20} style={{width:'100%', padding:'0.5rem'}}>{state_response_try_content}</textarea>
                                <div>
                                    <Button onClick={() => handle_click({switch:'edit-response-off'})} variant='secondary' size='sm'>放弃编辑</Button>
                                    <Button onClick={() => props.handle_click({switch:'submit-response', mail_uuid_top:state_response_mail_uuid_top, source:state_response_source, response_try_id:state_response_try_id_selected, content:state_response_try_content})} style={{float:'right'}} variant='primary' size="sm">确认送出回信</Button>
                                </div>
                            </td>
                        </tr>
                    ) 
                    : (
                        props.data_response.map((item) => {
                            return (
                                <tr key={unique_key++}>
                                    <td><Button onClick={(e) => handle_click({switch:'edit-response-on', mail_uuid_top:item.mail_uuid_top, source:item.source, response_try_id:item.response_try_id, content:item.content})} variant='primary' size="sm">选<br />择</Button></td>
                                    <td style={{textAlign:'left', whiteSpace:'pre-wrap'}}>{item.content}</td>
                                </tr>
                            );
                        })
                    )}
                </tbody>
            </Table>

            <div style={{marginTop:'0.5rem', marginBottom:'1rem', display:'flex', justifyContent:'space-between'}}>
                <div style={{width:'100%', textAlign:'left', marginLeft:'1rem'}}><Button onClick={(e) => props.handle_click({switch:'view-list'})} variant="secondary" size="sm">返回</Button></div>
            </div>
        </div>
    );
};

export default MainReactObj;
