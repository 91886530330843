import React, { useState } from 'react';
import jwtDecode from 'jwt-decode';
import { styled } from 'styled-components';
import axios from 'axios';

import { url_host_rest } from '../credentials/base';

const MainReactObj = (props) => {
    let token_decoded = localStorage.getItem('token') ? jwtDecode(localStorage.getItem('token')) : null;
    if (token_decoded) {
        if (token_decoded.is_staff) {
            window.location.href = '/backops';
        }
        else {
            window.location.href = '/';
        }
    }

    const [state_email, set_email] = useState('');
    const [state_password, set_password] = useState('');
    const [state_password_reset_mode, set_password_reset_mode] = useState(false);
    const [state_password_reset_email, set_password_reset_email] = useState('');

    const handle_click = (data) => {
        switch (data.switch) {
            case 'password-reset':
                set_password_reset_email('');
                set_password_reset_mode(true);
            break;

            case 'password-reset-cancel':
                set_password_reset_mode(false);
            break;

            case 'password-reset-submit':
                data.e.preventDefault();

                // validate
                if (state_password_reset_email.trim() === '') {
                    alert('请输入 Email');
                    return;
                }

                alert('您输入的 Email:\n' + state_password_reset_email.trim() + '\n\n若与资料符合，我们会发密码重设邮件到您的邮箱\n邮件有效期限为 10分钟～' + '\n\n密码重设邮件可能会被归类成 垃圾邮件，请记得检查');
                password_reset_submit();
            break;

            case 'login':
                data.e.preventDefault();

                // validate
                login_submit();
            break;
            default:
        }
    };

    const password_reset_submit = async () => {
        let res = null;

        res = await axios({
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            url: url_host_rest + '/user/password_reset_start_02',
            data: {
                email: state_password_reset_email.trim(),
            },
        })
    
        if (res && res.data && res.data.hasOwnProperty('message')) {
            if (res.data.message === "COMPLETED") {
                window.location.reload();
                return;
            }
        }

        alert('发送信件错误');
    };

    const login_submit = async () => {
        let res = null;
        
        res = await fetch(url_host_rest + '/user/login_02', {
            method: "POST",
            headers: {
                "Content-Type": 'application/json',
            },
            body: JSON.stringify({
                email: state_email.trim(),
                password: state_password.trim(),    
            }),
        });
        res = await res.json();
        if (res && res.token) {
            localStorage.setItem('token', res.token);
            window.location.href = '/backops_login';
        }
        else {
            if (res && res.message) {
                alert(res.message);
            }
        }
    };

    return (
        <Main>
            <Logo src={'/img/qujieyou_logo.png'} />
            <LogoSub src={'/img/qujieyou_logo_b.png'} />
            <LogoSub src={'/img/qujieyou_logo_c.png'} />
            <div style={{paddingTop:'1rem', marginLeft:'auto', marginRight:'auto', width:'30%'}}>～作业网～</div>

            <form onSubmit={(e) => handle_click({switch:'login', e:e})}>
                <InputWrapper>
                    <Input value={state_email} onChange={(e) => set_email(e.target.value)} type='email' placeholder='请输入 Email 登入' />
                </InputWrapper>

                <InputWrapper>
                    <Input value={state_password} onChange={(e) => set_password(e.target.value)} type='password' placeholder='密码' />
                </InputWrapper>

                <div style={{marginTop:'3rem'}}>
                    {/* <input checked={state_terms_checked} onChange={() => handle_change({switch:'terms-check'})} type="checkbox" /> */}
                    {/* <span style={{marginLeft:'0.25rem', color:'#838383', cursor:'pointer'}} onClick={() => handle_click({switch:'terms-view'})}>用户协议</span> */}
                </div>
                <Button type='submit'>确定</Button>
            </form>

            <div onClick={(e) => handle_click({switch:'password-reset'})} style={{display:state_password_reset_mode ? 'none' : 'block', marginTop:'1rem', marginBottom:'1rem', color:'#838383', cursor:'pointer'}}>重设密码</div>

            <form onSubmit={(e) => handle_click({switch:'password-reset-submit', e:e})} style={{display:state_password_reset_mode ? 'block' : 'none'}}>
                <InputWrapper>
                    <Input value={state_password_reset_email} onChange={(e) => set_password_reset_email(e.target.value)} type='email' placeholder='请输入 Email 重设密码' />
                </InputWrapper>

                <Button type='submit'>重设密码</Button>
            </form>
            <div onClick={(e) => handle_click({switch:'password-reset-cancel'})} style={{display:state_password_reset_mode ? 'block' : 'none', marginTop:'1rem', marginBottom:'1rem', color:'#838383', cursor:'pointer'}}>取消重设密码</div>

            <div style={{paddingTop:'3rem', paddingBottom:'3rem'}}>&nbsp;</div>
        </Main>
    );
};

export default MainReactObj;

const Button = styled.button`
    border-radius: 40px;
    box-sizing: border-box;
    background-color: #BC7646;
    border: 0;
    font-size: 1.2rem;
    padding: 1rem 4rem 1rem 4rem;
    margin-top: 1rem;
    color: #FFFFFF;
`;

const Input = styled.input`
    height: 3rem;
    width: 90%;
    border-radius: 10px;
    border: 0;
    margin-top: 2vmax;
    // text-align: right;
    padding: 0.8rem 1rem;
    box-sizing: border-box;
    font-size: 1rem;
`;

const InputWrapper = styled.div`
    position: relative;

    span {
        position: absolute;
        top: 1.9rem;
        left: 2.3rem;
        color: #838383;
    }

    @media (min-width: 500px) {
        span {
            top: 2.5rem;
            left: 2.5rem;
        }    
    }
`;

const Logo = styled.img`
    display: block;
    padding-top: 7rem;
    margin-left: auto;
    margin-right: auto;
    width: 30%;
`;

const LogoSub = styled.img`
    display: block;
    padding-top: 1rem;
    margin-left: auto;
    margin-right: auto;
    width: 30%;
`;

const Main = styled.div`
    text-align: center;
    background-color: #F7F4F2;
    // height: 100vh;

    @media (min-width: 500px) {
        max-width: 500px;
        margin: auto;
    }
`;
