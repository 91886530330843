import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

// import './App.css';
// import SpinnerBackdrop from './components/UI/SpinnerBackdrop';

import Backops from './containers/Backops';
import BackopsLogin from './containers/BackopsLogin';
import BackopsPasswordReset from './containers/BackopsPasswordReset';
import Send from './containers/Send';
import Home from './containers/Home';
import Login from './containers/Login';
import Terms from './containers/Terms';
// const Send = React.lazy(() => import('./containers/Send'));

const MainReactObj = () => {
    
    useEffect(() => {
        let token = localStorage.getItem('token');
        // can't user check_login() here because !token is OK
        if (!token) {
            return;
        }
        if (token) {
            let token_decoded = jwtDecode(token);
            if (token_decoded.time_expiry) {
                if (new Date(token_decoded.time_expiry) < new Date()) {
                    localStorage.removeItem('token');
                    window.location.href = '/';
                    return;
                }
            }
        }
    });

    let routes = (
        <BrowserRouter>
            <Routes>
                {/* <Route path='/send' render={ () => (<Suspense fallback={<div><Spinner /></div>}>
                    <Send />
                </Suspense>) } /> */}
                <Route path='/password_reset_02/:token' element={<BackopsPasswordReset />} />
                <Route path='/backops_login' element={<BackopsLogin />} />
                <Route path='/backops' element={<Backops />} />
                <Route path='/login' element={<Login />} />
                <Route path='/terms' element={<Terms />} />
                <Route path='/send/:uuid_mail' element={<Send />} />
                {/* 2023-12-11 jlin prevent users from using this platform.  All traffic should be to the wechat platform */}
                <Route path='/send' element={<Navigate to='/login' replace />} />
                {/* <Route path='/send' element={<Send />} /> */}
                <Route path='/:uuid' element={<Home 
                    view='chain'
                />} />
                <Route path='/' element={<Home 
                    view='list'
                />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </BrowserRouter>
    );

    return (
        <React.Fragment>
            {routes}
        </React.Fragment>
    );
};

export default MainReactObj;