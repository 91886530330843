// there are two scenarios of usage here:
// 1. when uuid_mail = null, this means user is viewing the mailbox list
// 2. when uuid_mail = useParam().uuid_mail, this means user is viewing the mail chain, and uuid_mail means uuid_top

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { styled } from 'styled-components';
import jwtDecode from 'jwt-decode';
import { url_host_rest } from '../credentials/base';

import BackopsMailResponseListView from '../components/Backops/Mail/ResponseListView';
import BackopsMailResponseChainView from '../components/Backops/Mail/ResponseChainView';

const MainReactObj = (props) => {
    // check for staff
    let token_decoded = localStorage.getItem('token') ? jwtDecode(localStorage.getItem('token')) : null;
    if (!token_decoded || !token_decoded.is_staff) {
        window.location.href = '/backops_login';
    }

    let params = useParams();
    let uuid_mail = params.uuid ? params.uuid : null;

    const [state_data_list, set_data_list] = useState([]);
    const [state_data_chain, set_data_chain] = useState([]);
    const [state_data_response, set_data_response] = useState([]);
    const [state_view_response_mail_uuid_top, set_view_response_mail_uuid_top] = useState(null);
    const [state_saving, set_saving] = useState(false);

    useEffect(() => {
        get_data_list();
    }, []);

    const get_data_response = async (mail_uuid_top) => {
        let res = null

        res = await fetch(url_host_rest + '/mail/get_backops_mail_response', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
            body: JSON.stringify({
                mail_uuid_top: mail_uuid_top,
            })
        });
        res = await res.json();
        let results = res.results;
        for (let a in results) {
            if (results[a]) {
                results[a].created_on_local = new Date(results[a].created_on).toLocaleString('sv-SE').substring(0, 16);
            }
        }
        set_data_response(results);
    }

    const get_data_chain = async (mail_uuid_top) => {
        let res = null

        res = await fetch(url_host_rest + '/mail/get_backops_mail_chain', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
            body: JSON.stringify({
                mail_uuid_top: mail_uuid_top,
            })
        });
        res = await res.json();
        let results = res.results;
        for (let a in results) {
            if (results[a]) {
                results[a].mail_created_on_local = new Date(results[a].mail_created_on).toLocaleString('sv-SE').substring(0, 16);
            }
        }
        set_data_chain(results);
    }

    const get_data_list = async () => {
        let res = null

        res = await fetch(url_host_rest + '/mail/get_backops_mail_response_list', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
            body: JSON.stringify({
                offset: 0,
                limit: 20,
            })
        });
        res = await res.json();
        let results = res.results;
        for (let a in results) {
            if (results[a]) {
                results[a].created_on_local = new Date(results[a].min_created_on).toLocaleString('sv-SE').substring(0, 16);
            }
        }
        set_data_list(results);
    }

    const handle_click = async (data) => {
        switch (data.switch) {
            case 'logout':
                localStorage.removeItem('token');
                window.location.href = '/backops';
            break;

            case 'view-response':
                set_view_response_mail_uuid_top(data.mail_uuid_top);
                get_data_chain(data.mail_uuid_top);
                get_data_response(data.mail_uuid_top);
            break;

            case 'view-list':
                set_view_response_mail_uuid_top(null);
            break;

            case 'submit-response':
                if (state_saving) { return; }
                
                set_saving(true);

                let response = window.confirm('确认送出回信？');
                if (!response) { 
                    set_saving(false);
                    return; 
                }
                
                let res = await fetch(url_host_rest + '/mail/create_backops_mail_response', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    },
                    body: JSON.stringify({
                        mail_uuid_top: data.mail_uuid_top,
                        source: data.source,
                        response_try_id: data.response_try_id,
                        content: data.content,
                    })
                });

                res = await fetch(url_host_rest + '/mail/create_mail', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    },
                    body: JSON.stringify({
                        created_by: 0,
                        uuid_top: data.mail_uuid_top,
                        source: data.source,
                        content: data.content,
                        create_02: "NO",
                    })
                });

                res = await fetch(url_host_rest + '/mail/complete_backops_mail_response', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    },
                    body: JSON.stringify({
                        mail_uuid_top: data.mail_uuid_top,
                    })
                });

                res = await fetch(url_host_rest + '/mail/delete_backops_mail_receive', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    },
                    body: JSON.stringify({
                        mail_uuid_top: data.mail_uuid_top,
                    })
                });

                alert('送出成功！');
                window.location.reload();
            break;

            default:
        }
    };

    let content = null;
    if (state_view_response_mail_uuid_top) {
        content = (
            <BackopsMailResponseChainView 
                data_chain={state_data_chain}
                data_response={state_data_response}
                handle_click={handle_click}
            />
        );
    }
    else {
        content = (
            <BackopsMailResponseListView
                data_list={state_data_list}
                handle_click={handle_click}
            />
        );
    }

    return (
        <Main style={{display:(token_decoded && token_decoded.uuid) ? 'block' : 'none'}}>
            <Navbar style={{display:'flex', justifyContent:'space-between', color:'#FFFFFF'}}>
                <div onClick={() => handle_click({switch:'logout'})} style={{cursor:'pointer', marginTop:'auto', marginBottom:'auto', marginLeft:'1rem', marginRight:'1rem', fontSize:'1.3rem'}}>登出</div>
                <div style={{marginTop:'auto', marginBottom:'auto', marginLeft:'1rem', marginRight:'1rem', fontSize:'1.4rem'}}>作业网</div>
                <div style={{padding:'0.75rem', fontSize:'1.2rem'}}>{token_decoded ? token_decoded.name_full : ''}</div>
            </Navbar>

            {content}
        </Main>
    );
};

export default MainReactObj;

const Button = styled.button`
    width: 90%;
    border-radius: 40px;
    box-sizing: border-box;
    background-color: #BC7646;
    border: 0;
    font-size: 1.2rem;
    padding: 0.5rem 2rem 0.5rem 2rem;
    margin-top: 0.5rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
    color: #FFFFFF;
    cursor: pointer;
`;

const Navbar = styled.div`
    /* height: 40vmin; */
    /* pointer-events: none; */

    background-image: url('/img/navbar.jpg');
    background-size: auto;
    height: 6vmax;

    @media (min-width: 500px) {
        height: 4vmax;
    }
`;

const Background = styled.div`
    // height: 92vmax;
    /* background-position: center; */
    /* background-size: cover; */

    min-height: 90vh;
    padding: 1rem 0 0.5rem 0;
    background-image: url('/img/background.jpg');

    @media (min-width: 500px) {
        min-height: 87vh;
    }
`;

const Main = styled.div`
    text-align: center;

    @media (min-width: 500px) {
        max-width: 500px;
        margin: auto;
    }
`;
