import axios from 'axios';

import { url_host_rest } from '../credentials/base';

// this function returns either true or false boolean
export const check_if_pending_response = async () => {
    let res = null

    res = await axios({
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
        url: url_host_rest + '/mail/check_if_pending_response',
        data: {},
    })

    if (res && res.data && res.data.hasOwnProperty('result')) {
        return res.data.result;
    }
    // default to returning true to block users from sending mail
    return true;
};

