// there are three scenarios of usage here:
// 1. when uuid = null, this means the user is not logged in, therefore it is the new user registration and send mail UI
// 2. when uuid = token_decoded.uuid, this means the user is composing a new thread
// 3. when uuid = token_decoded.uuid && uuid_mail = useParam(), this means user is replying to yeye

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { styled } from 'styled-components';
import DatePicker from 'react-datepicker';
import { url_host_rest } from '../credentials/base';
import { validate_email } from '../helpers/validate';

import "react-datepicker/dist/react-datepicker.css";
import "./Send.css";
import SpinnerBackdrop from '../components/UI/SpinnerBackdrop';
import { check_if_pending_response } from '../helpers/base';

const MainReactObj = () => {
    let navigate = useNavigate();
    let params = useParams();
    let unique_key = 0;

    let uuid = null;
    let username = null;
    let token_decoded = localStorage.getItem('token') ? jwtDecode(localStorage.getItem('token')) : null;
    if (token_decoded && token_decoded.uuid) {
        uuid = token_decoded.uuid;
        username = token_decoded.username;
    }

    let uuid_mail = params.uuid_mail;

    const [state_username, set_username] = useState('');
    const [state_password, set_password] = useState('');
    const [state_password_check, set_password_check] = useState('');
    const [state_email, set_email] = useState('');
    const [state_email_check, set_email_check] = useState('');
    const [state_gender, set_gender] = useState(0);
    const [state_terms_checked, set_terms_checked] = useState(false);
    const [state_dob, set_dob] = useState(new Date(2010, 0, 1));
    const [state_message, set_message] = useState('');
    const [state_word_count, set_word_count] = useState(0);
    const [state_data_list, set_data_list] = useState([]);
    const [state_sending, set_sending] = useState(false);

    useEffect(() => {
        set_username(localStorage.getItem('username') ? localStorage.getItem('username') : '');
        set_gender(localStorage.getItem('gender') ? Number(localStorage.getItem('gender')) : 0);
        set_email(localStorage.getItem('email') ? localStorage.getItem('email') : '');
        set_message(localStorage.getItem('message') ? localStorage.getItem('message') : '');

        // if replying
        if (uuid && uuid_mail) {
            check_if_pending_response()
            .then(res => {
                if (res) {
                    window.location.href = '/' + uuid_mail;
                }
                else {
                    get_data_chain();
                }
            });
        }
        // if sending new
        else if (uuid) {
            check_if_pending_response()
            .then(res => {
                if (res) {
                    window.location.href = '/';
                }
            });
        }
    }, []);

    useEffect(() => {
        set_word_count(state_message.length);
    }, [state_message]);

    const get_data_chain = async () => {
        let res = null

        res = await fetch(url_host_rest + '/mail/get_mail_chain', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
            body: JSON.stringify({
                uuid: uuid_mail,
                uuid_user: uuid,
            })
        });
        res = await res.json();
        let results = res.results;
        for (let a in results) {
            if (results[a]) {
                // await update_read_on(results[a].uuid);
                results[a].created_on_local = new Date(results[a].created_on).toLocaleString('sv-SE').substring(0, 16);
            }
        }
        set_data_list(results);
    }

    const handle_input = (data) => {
        switch (data.switch) {
            case 'username':
                set_username(data.value);
                if (!uuid) { localStorage.setItem('username', data.value); }
            break;

            case 'password':
                set_password(data.value);
            break;

            case 'password_check':
                set_password_check(data.value);
            break;

            case 'gender':
                set_gender(data.value);
                if (!uuid) { localStorage.setItem('gender', data.value); }
            break;

            case 'email':
                set_email(data.value);
                if (!uuid) { localStorage.setItem('email', data.value); }
            break;

            case 'email_check':
                set_email_check(data.value);
            break;

            case 'message':
                set_message(data.value);
                localStorage.setItem('message', data.value);
            break;

            default:
        }
    };

    const handle_click = (data) => {
        switch (data.switch) {
            case 'logout':
                localStorage.removeItem('token');
                window.location.href = '/';
            break;

            case 'terms-view':
                // navigate('/terms');
                window.location.href = '/terms';
            break;

            default:
        }
    };

    const handle_change = (data) => {
        switch (data.switch) {
            case 'terms-check':
                set_terms_checked(prev => {
                    let next = (prev === true ? false : true);
                    return next;
                });
            break;

            default:
        }
    };

    const handle_submit = async () => {
        if (state_sending) {
            return;
        }
        set_sending(true);

        let res = null;
        let user_uuid = null;
        let response = null;

        // validate both scenarios
        if (state_message.trim().length === 0) {
            alert('要填写内容呀');
            set_sending(false);
            return;
        }
        if (state_message.trim().length > 800) {
            alert('内容不能超过800字');
            set_sending(false);
            return;
        }
    
        if (!uuid) {
            // validate for new user scenario
            if (state_username.trim() === '') {
                alert("请填写笔名");
                set_sending(false);
                return;
            }
            if (state_username.trim().length > 8) {
                alert("请检查笔名\n笔名太长了");
                set_sending(false);
                return;
            }
            if (state_username.trim().indexOf(' ') > -1) {
                alert("请检查笔名\n笔名不能含有空格");
                set_sending(false);
                return;
            }

            // validate password related
            if (state_password.trim().length < 8) {
                alert("密码最少需要8个字母");
                set_sending(false);
                return;
            }
            if (state_password.trim() !== state_password_check.trim()) {
                alert("密码确认错误，两组密码必须一致");
                set_sending(false);
                return;
            }

            // validate email related
            if (false === validate_email(state_email.trim())) {
                alert("请检查电子邮箱格式");
                set_sending(false);
                return;
            }
            res = await fetch(url_host_rest + '/user/check_username_exist', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    username: state_username.trim(),
                })
            });
            res = await res.json();
            if (res.message === "YES") {
                alert("请更换笔名\n已经有人使用这个笔名了");
                set_sending(false);
                return;
            }
            res = await fetch(url_host_rest + '/user/check_email_exist', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: state_email.trim(),
                })
            });
            res = await res.json();
            if (res.message === "YES") {
                alert("请更换电子邮箱\n已经有人使用这个电子邮箱了");
                set_sending(false);
                return;
            }
            if (state_email.trim() !== state_email_check.trim()) {
                alert("电子邮箱确认错误，两组电子邮箱必须一致");
                set_sending(false);
                return;
            }

            // validate terms is checked
            if (!state_terms_checked) {
                alert("请勾选同意用户协议");
                set_sending(false);
                return;
            }

            response = window.confirm('确定寄信了吗');
            if (!response) { 
                set_sending(false);
                return; 
            }

            // validate OK, proceed to save
            res = await fetch(url_host_rest + '/user/create_user', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    username: state_username.trim(),
                    password: state_password.trim(),
                    gender: state_gender,
                    email: state_email.trim(),
                })
            });
            res = await res.json();
            if (res.message !== "COMPLETED") { 
                alert("储存帐号发生错误");
                set_sending(false);
                return;
            }

            user_uuid = res.uuid;
        }
        else {
            response = window.confirm('确定寄信了吗');
            if (!response) { 
                set_sending(false);
                return; 
            }

            user_uuid = uuid;
        }

        let body_obj = { 
            content: state_message,
            created_by: user_uuid,
            create_02: "YES",
        };
        if (uuid && uuid_mail) {
            body_obj.uuid_top = uuid_mail;
        }
        res = await fetch(url_host_rest + '/mail/create_mail', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body_obj),
        })
        res = res.json();
        localStorage.removeItem('username');
        localStorage.removeItem('gender');
        localStorage.removeItem('email');
        localStorage.removeItem('message');
        alert('发送成功！');
        set_sending(false);
        window.location.href = '/';
    };

    return (
        <React.Fragment>
        {state_sending ? <SpinnerBackdrop show_backdrop={true} text="发送中" /> : null}
        <Main>
            <Navbar style={{display:'flex', justifyContent:'space-between', color:'#FFFFFF'}}>
                {uuid 
                ? (<div onClick={() => handle_click({switch:'logout'})} style={{cursor:'pointer', marginTop:'auto', marginBottom:'auto', marginLeft:'1rem', marginRight:'1rem', fontSize:'1.3rem'}}>登出</div>) 
                : <div style={{marginLeft:'1rem', marginRight:'1rem'}}>　　</div>}
                {/* <div style={{width:'4rem', padding:'0.75rem'}}><img src={'/img/qujieyou_logo.jpg'} style={{height:'3rem', borderRadius:'10px'}} /></div> */}
                <div style={{marginTop:'auto', marginBottom:'auto', marginLeft:'1rem', marginRight:'1rem', fontSize:'1.4rem'}}>写信</div>
                <div onClick={() => handle_submit()} style={{cursor:'pointer', marginTop:'auto', marginBottom:'auto', marginLeft:'1rem', marginRight:'1rem', fontSize:'1.3rem'}}>发送</div>
            </Navbar>

            <Background>
                <div style={{display:(uuid ? 'none' : 'block')}}>
                    <InputWrapper>
                        <span>笔名</span>
                        <Input value={state_username} onChange={(e) => handle_input({switch:'username', value:e.target.value})} placeholder='方便杂货店记住你，也要你不忘记' />
                    </InputWrapper>

                    <InputWrapper>
                        <span>密码</span>
                        <Input value={state_password} onChange={(e) => handle_input({switch:'password', value:e.target.value})} type="password" />
                    </InputWrapper>

                    <InputWrapper>
                        <span>确认密码</span>
                        <Input value={state_password_check} onChange={(e) => handle_input({switch:'password_check', value:e.target.value})} type="password" />
                    </InputWrapper>

                    <InputWrapper>
                        <span style={{top:'0.9rem'}}>性别</span>
                        <InputFakeDiv>
                            <label><input checked={state_gender == 1} onChange={() => handle_input({switch:'gender', value:1})} type="radio" /> 男</label>
                            &nbsp;&nbsp;&nbsp;
                            <label><input checked={state_gender == 2} onChange={() => handle_input({switch:'gender', value:2})} type="radio" /> 女</label>
                        </InputFakeDiv>
                    </InputWrapper>

                    <InputWrapper>
                        <span>电子邮箱</span>
                        <Input value={state_email} onChange={(e) => handle_input({switch:'email', value:e.target.value})} type='email' placeholder='方便我们长久保持联系' />
                    </InputWrapper>

                    <InputWrapper>
                        <span>确认电子邮箱</span>
                        <Input value={state_email_check} onChange={(e) => handle_input({switch:'email_check', value:e.target.value})} type='email' />
                    </InputWrapper>

                    <div style={{marginTop:'2vmax', marginLeft:'auto', marginRight:'auto', width:'90%', textAlign:'right'}}>
                        <input checked={state_terms_checked} onChange={() => handle_change({switch:'terms-check'})} type="checkbox" />
                        <span style={{marginLeft:'0.25rem', color:'#838383', cursor:'pointer'}} onClick={() => handle_click({switch:'terms-view'})}>我已同意用户协议</span>
                    </div>

                    {/* cannot use InputWrapper here cuz for some reason it will fuck up the DatePicker's arrows and displays */}
                    {/* <DatePickerWrapper>
                        <span>出生日期</span>
                        <DatePicker selected={state_dob} onChange={(date) => set_dob(date)} dateFormat={'yyyy-MM-dd'} className='date-picker' popperPlacement='bottom-end' showYearDropdown />
                    </DatePickerWrapper> */}
                </div>
                
                <Modal>
                    <div style={{padding:'1rem', height:'100%', boxSizing:'border-box'}}>
                        <div style={{display:'flex', justifyContent:'space-between', borderBottom:'1px solid #AAAAAA'}}>
                            <div style={{marginBottom:'0.5rem'}}>
                                {uuid 
                                ? (uuid_mail ? '回信给爷爷' : '寄给爷爷') 
                                : '寄给爷爷的第一封信'}
                            </div>
                            <div style={{marginBottom:'0.5rem'}}>{state_word_count} / 800</div>
                        </div>
                        <div onSubmit={handle_submit} style={{marginTop:'1rem'}}>
                            <textarea
                                type="text"
                                value={state_message}
                                onChange={(e) => handle_input({switch:'message', value:e.target.value})}
                                style={{fontSize:'1rem', color:'#939393', width:'100%', boxSizing:'border-box', height:'27rem', border:'none', overflow:'auto', outline:'none', resize:'none', boxShadow:'none'}}
                                rows={27}
                            >
                            </textarea>
                        </div>
                    </div>
                </Modal>

                <div style={{display:(uuid_mail ? 'block' : 'none')}}>
                    <MailWindow>
                        {state_data_list.map(item => {
                            return (
                                <div key={unique_key++} style={{fontSize:'1.2rem', padding:'1rem 1rem 0 1rem', boxSizing:'border-box'}}>
                                    <div style={{display:'flex', justifyContent:'space-between', borderBottom:'1px solid #AAAAAA'}}>
                                        <div style={{marginBottom:'0.3rem', color:'#838383'}}>{item.created_on_local}</div>
                                        <div style={{marginBottom:'0.3rem', color:'#838383'}}>{item.created_by_uuid === uuid ? username : '老爷爷'}</div>
                                    </div>
                                    <div style={{marginTop:'0.5rem', marginBottom:'1rem', position:'relative', height:'76%', overflow:'hidden', overflowWrap:'break-word', whiteSpace:'pre-wrap'}}>{item.content}</div>
                                </div>    
                            );
                        })}
                    </MailWindow>
                </div>
            </Background>
        </Main>
        </React.Fragment>
    );
};

export default MainReactObj;

const MailWindow = styled.div`
    // position: absolute;
    // margin-left: 5%;

    width: 90%;
    min-height: 88vh;
    margin: auto;
    margin-bottom: 1rem;
    margin-top: 2vmax;
    padding-bottom: 1rem;
    background-color: #FFFFFF;
    border-radius: 10px;
    box-sizing: border-box;
    text-align: left;

    @media (min-width: 500px) {
        width: 450px;
    }
`;

const Button = styled.button`
    width: 90%;
    border-radius: 40px;
    box-sizing: border-box;
    background-color: #BC7646;
    border: 0;
    font-size: 1.2rem;
    padding: 0.5rem 2rem 0.5rem 2rem;
    margin-top: 0.5rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
    color: #FFFFFF;
    cursor: pointer;
`;

const Select = styled.select`
    height: 3rem;
    width: 90%;
    border-radius: 10px;
    border: 0;
    margin-top: 2vmax;
    text-align: right;
    padding: 0.8rem 1rem;
    box-sizing: border-box;
    font-size: 1rem;

    :disabled {
        color: #838383;
    }
`;

const DatePickerWrapper = styled.div`
    position: relative;

    span {
        position: absolute;
        top: 1.9rem;
        left: 2.3rem;
        z-index: 2;
        color: #838383;
    }

    @media (min-width: 500px) {
        span {
            top: 2.5rem;
            left: 2.5rem;
        }    
    }
`;

const InputWrapper = styled.div`
    position: relative;

    span {
        position: absolute;
        top: 1.9rem;
        left: 2.3rem;
        color: #838383;
    }

    @media (min-width: 500px) {
        span {
            top: 38px;
            left: 42px;
        }    
    }
`;

const InputFakeDiv = styled.div`
    height: 3rem;
    width: 90%;
    border-radius: 10px;
    border: 0;
    margin-top: 2vmax;
    margin-left: auto;
    margin-right: auto;
    text-align: right;
    padding: 0.8rem 1rem;
    box-sizing: border-box;
    font-size: 1rem;
    background-color: #FFFFFF;
`;

const Input = styled.input`
    height: 3rem;
    width: 90%;
    border-radius: 10px;
    border: 0;
    margin-top: 2vmax;
    text-align: right;
    padding: 0.8rem 1rem;
    box-sizing: border-box;
    font-size: 1rem;
`;

const Modal = styled.div`
    // position: absolute;
    width: 90%;
    height: 32rem;
    // margin-left: 5%;
    margin: 2vmax auto 2vmax auto;
    // margin-top: 2vmax;
    background-color: #FFFFFF;
    border-radius: 10px;
    box-sizing: border-box;
    text-align: left;

    @media (min-width: 500px) {
        width: 450px;
        margin: auto;
        margin-top: 2vmax;
    }
`;

const Navbar = styled.div`
    /* height: 40vmin; */
    /* pointer-events: none; */

    background-image: url('/img/navbar.jpg');
    background-size: auto;
    height: 8vmax;

    @media (min-width: 500px) {
        height: 5vmax;
    }
`;

const Background = styled.div`
    // height: 92vmax;
    /* background-position: center; */
    /* background-size: cover; */

    padding-top: 0.5rem;
    padding-bottom: 0.6rem;
    background-image: url('/img/background.jpg');
    min-height: 90vh;

    @media (min-width: 500px) {

    }
`;

const Main = styled.div`
    text-align: center;

    @media (min-width: 500px) {
        max-width: 500px;
        margin: auto;
    }
`;
