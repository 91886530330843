// there are two scenarios of usage here:
// 1. when uuid_mail = null, this means user is viewing the mailbox list
// 2. when uuid_mail = useParam().uuid_mail, this means user is viewing the mail chain, and uuid_mail means uuid_top

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { styled } from 'styled-components';
import jwtDecode from 'jwt-decode';
import { url_host_rest } from '../credentials/base';

import { check_if_pending_response } from '../helpers/base';

const MainReactObj = (props) => {
    let uuid = null;
    let username = null;
    let token_decoded = localStorage.getItem('token') ? jwtDecode(localStorage.getItem('token')) : null;
    if (token_decoded && token_decoded.uuid) {
        uuid = token_decoded.uuid;
        username = token_decoded.username;
    }
    else {
        window.location.href = '/login';
    }

    let params = useParams();
    let uuid_mail = params.uuid;

    const [state_data_list, set_data_list] = useState([]);
    const [state_data, set_data] = useState({
        is_pending_response: false,
    });

    useEffect(() => {
        check_if_pending_response()
        .then(res => {
            set_data(prev => {
                let next = {...prev};
                next.is_pending_response = res;
                return next;
            });        
        });

        if (props.view === 'list') {
            get_data_list();
        }
        if (props.view === 'chain') {
            get_data_chain();
        }
    }, []);

    const update_read_on = async (uuid) => {
        let res = null

        res = await fetch(url_host_rest + '/mail/update_read_on', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
            body: JSON.stringify({
                uuid: uuid,
                read_on: new Date(),
            })
        });
    };

    const get_data_chain = async () => {
        let res = null

        res = await fetch(url_host_rest + '/mail/get_mail_chain', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
            body: JSON.stringify({
                uuid: uuid_mail,
                uuid_user: uuid,
            })
        });
        res = await res.json();
        let results = res.results;
        for (let a in results) {
            if (results[a]) {
                await update_read_on(results[a].uuid);
                results[a].created_on_local = new Date(results[a].created_on).toLocaleString('sv-SE').substring(0, 16);
            }
        }
        set_data_list(results);
    }

    const get_data_list = async () => {
        let res = null

        res = await fetch(url_host_rest + '/mail/get_mail_list', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
            body: JSON.stringify({
                uuid: uuid,
            })
        });
        res = await res.json();
        let results = res.results;
        for (let a in results) {
            if (results[a]) {
                results[a].created_on_local = new Date(results[a].created_on).toLocaleString('sv-SE').substring(0, 16);
                results[a].created_on_local_date = new Date(results[a].created_on).toLocaleString('sv-SE').substring(0, 10);
            }
        }
        results.sort((a, b) => {
            let sort_on_top_a = !a.latest_read_on && a.latest_by === '老爷爷';
            let sort_on_top_b = !b.latest_read_on && b.latest_by === '老爷爷';
            if (sort_on_top_a && !sort_on_top_b) { return -1; }
            if (!sort_on_top_a && sort_on_top_b) { return 1; }
            if (a.created_on > b.created_on) { return -1; }
            if (a.created_on < b.created_on) { return 1; }
            return 0;
        });
        set_data_list(results);
    }

    const handle_click = (data) => {
        switch (data.switch) {
            case 'view-chain':
                window.location.href = '/' + data.uuid;
            break;

            case 'compose':
                window.location.href = 'http://yjzhpweb.vip.hnhxzkj.com';
                // window.location.href = '/send';
            break;

            case 'reply':
                window.location.href = '/send/' + uuid_mail;
            break;

            case 'logout':
                localStorage.removeItem('token');
                window.location.href = '/';
            break;
            default:
        }
    };

    return (
        <Main style={{display:uuid ? 'block' : 'none'}}>
            <Navbar style={{display:'flex', justifyContent:'space-between', color:'#FFFFFF'}}>
                <div onClick={() => handle_click({switch:'logout'})} style={{cursor:'pointer', marginTop:'auto', marginBottom:'auto', marginLeft:'1rem', marginRight:'1rem', fontSize:'1.3rem'}}>登出</div>
                <div style={{marginTop:'auto', marginBottom:'auto', marginLeft:'1rem', marginRight:'1rem', fontSize:'1.4rem'}}>杂货店信箱</div>
                <div style={{width:'4rem', padding:'0.75rem'}}>
                    &nbsp;
                    {/* <img src={'/img/qujieyou_logo.jpg'} style={{height:'3rem', borderRadius:'10px'}} /> */}
                </div>
            </Navbar>

            <Background>
                <div style={{display:(props.view === 'list' ? 'block' : 'none')}}>
                    <div style={{fontSize:'1.3rem', color:'#FFFFFF'}}>{username}，欢迎回来</div>

                    {state_data.is_pending_response 
                    ? (<div><Button onClick={() => handle_click({switch:''})} style={{cursor:'default', fontSize:'1.1rem'}}>已收到您的信件，爷爷会尽快回复！</Button></div>) 
                    : (<div><Button onClick={() => handle_click({switch:'compose'})}>写信给杂货店</Button></div>)}
                    

                    {state_data_list.map(item => {
                        return (
                            <MailPane style={{backgroundImage:(item.latest_by === '我' ? "url('/img/stamp-me.png')" : "url('/img/stamp-yeye.png')"), backgroundSize:'cover'}}>
                                <div onClick={() => handle_click({switch:'view-chain', uuid:item.uuid})} style={{padding:'1rem', height:'100%', boxSizing:'border-box', cursor:'pointer'}}>
                                    <div style={{width:'75%'}}>
                                        <div style={{color:'#838383', fontSize:'1.2rem'}}>
                                            {item.latest_by} • {item.created_on_local_date}
                                            {(!item.latest_read_on && item.latest_by === '老爷爷') ? (<React.Fragment> • <span style={{color:'#FF0000'}}>未读回信</span></React.Fragment>) : null}
                                        </div>
                                        <div style={{fontSize:'1.2rem', marginTop:'0.5rem', position:'relative', height:'76%', overflow:'hidden', overflowWrap:'break-word'}}>{item.content_short} •  •  • </div>
                                    </div>
                                </div>
                            </MailPane>    
                        );
                    })}

                    <div style={{color:'#975C2E'}}>人的心声是绝对不能无视的。</div>
                </div>

                <div style={{display:(props.view === 'chain' ? 'block' : 'none')}}>
                    {/* <div style={{display:(state_data_list.length && state_data_list[0].created_by_uuid !== uuid) ? 'block' : 'none'}}>
                        {state_data.is_pending_response 
                        ? (<Button onClick={() => handle_click({switch:''})} style={{cursor:'default', fontSize:'1.1rem'}}>已收到您的信件，爷爷会尽快回复！</Button>) 
                        : (<Button onClick={() => handle_click({switch:'reply'})}>回信给爷爷</Button>)}
                    </div> */}

                    <MailWindow>
                        {state_data_list.map(item => {
                            return (
                                <div style={{fontSize:'1.2rem', padding:'1rem 1rem 0 1rem', boxSizing:'border-box'}}>
                                    <div style={{display:'flex', justifyContent:'space-between', borderBottom:'1px solid #AAAAAA'}}>
                                        <div style={{marginBottom:'0.3rem', color:'#838383'}}>{item.created_on_local}</div>
                                        <div style={{marginBottom:'0.3rem', color:'#838383'}}>{item.created_by_uuid === uuid ? username : '老爷爷'}</div>
                                    </div>
                                    <div style={{marginTop:'0.5rem', marginBottom:'1rem', position:'relative', height:'76%', overflow:'hidden', overflowWrap:'break-word', whiteSpace:'pre-wrap'}}>{item.content}</div>
                                </div>    
                            );
                        })}
                    </MailWindow>
                </div>
            </Background>
        </Main>
    );
};

export default MainReactObj;

const Button = styled.button`
    width: 90%;
    border-radius: 40px;
    box-sizing: border-box;
    background-color: #BC7646;
    border: 0;
    font-size: 1.2rem;
    padding: 0.5rem 2rem 0.5rem 2rem;
    margin-top: 0.5rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
    color: #FFFFFF;
    cursor: pointer;
`;

const MailWindow = styled.div`
    // position: absolute;
    // margin-left: 5%;

    width: 90%;
    min-height: 88vh;
    margin: auto;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    background-color: #FFFFFF;
    border-radius: 10px;
    box-sizing: border-box;
    text-align: left;

    @media (min-width: 500px) {
        width: 450px;
    }
`;

const MailPane = styled.div`
    // position: absolute;
    // margin-left: 5%;

    width: 90%;
    height: 45vmin;
    // height: 14rem;
    margin: auto;
    margin-bottom: 1rem;
    background-color: #FFFFFF;
    border-radius: 10px;
    box-sizing: border-box;
    text-align: left;

    @media (min-width: 500px) {
        width: 450px;
        height: 230px;
    }
`;

const Navbar = styled.div`
    /* height: 40vmin; */
    /* pointer-events: none; */

    background-image: url('/img/navbar.jpg');
    background-size: auto;
    height: 8vmax;

    @media (min-width: 500px) {
        height: 5vmax;
    }
`;

const Background = styled.div`
    // height: 92vmax;
    /* background-position: center; */
    /* background-size: cover; */

    min-height: 90vh;
    padding: 1rem 0 0.5rem 0;
    background-image: url('/img/background.jpg');

    @media (min-width: 500px) {
        min-height: 87vh;
    }
`;

const Main = styled.div`
    text-align: center;

    @media (min-width: 500px) {
        max-width: 500px;
        margin: auto;
    }
`;
