import React from 'react';

import Table from 'react-bootstrap/Table';

const MainReactObj = (props) => {
    const handle_click = (data) => {
        switch (data.switch) {
            default:
        }
    };

    return (
        <div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>回信产出时间</th>
                    </tr>
                </thead>
                <tbody>
                    {props.data_list.map((item) => {
                        return (
                            <tr onClick={(e) => props.handle_click({switch:'view-response', mail_uuid_top:item.mail_uuid_top})} key={item.mail_uuid_top}>
                                <td style={{cursor:'pointer'}}>{item.created_on_local} ({item.mail_created_by_username})</td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </div>
    );
};

export default MainReactObj;
