import React, { useState } from 'react';
import jwtDecode from 'jwt-decode';
import { styled } from 'styled-components';
import { url_host_rest } from '../credentials/base';

const MainReactObj = (props) => {
    let token_decoded = localStorage.getItem('token') ? jwtDecode(localStorage.getItem('token')) : null;
    if (token_decoded && token_decoded.uuid) {
        window.location.href = '/';
    }

    const [state_username, set_username] = useState('');
    const [state_password, set_password] = useState('');
    const [state_terms_checked, set_terms_checked] = useState(false);

    const handle_change = (data) => {
        switch (data.switch) {
            case 'terms-check':
                set_terms_checked(prev => {
                    let next = (prev === true ? false : true);
                    return next;
                });
            break;

            default:
        }
    };

    const handle_click = (data) => {
        switch (data.switch) {
            case 'terms-view':
                window.location.href = '/terms';
            break;

            case 'login':
                data.e.preventDefault();

                // validate
                login_submit();
            break;
            default:
        }
    };

    const login_submit = async () => {
        let res = null;
        
        res = await fetch(url_host_rest + '/user/login', {
            method: "POST",
            headers: {
                "Content-Type": 'application/json',
            },
            body: JSON.stringify({
                username: state_username.trim(),
                password: state_password.trim(),    
            }),
        })
        res = await res.json();
        if (res && res.token) {
            localStorage.setItem('token', res.token);
            window.location.href = '/';
        }
        else {
            if (res && res.message) {
                alert(res.message);
            }
        }
    };

    return (
        <Main onSubmit={(e) => handle_click({switch:'login', e:e})}>
            <Logo src={'/img/qujieyou_logo.png'} />
            <LogoSub src={'/img/qujieyou_logo_b.png'} />
            <LogoSub src={'/img/qujieyou_logo_c.png'} />

            <InputWrapper>
                <Input value={state_username} onChange={(e) => set_username(e.target.value)} type='text' placeholder='请输入笔名登入' />
            </InputWrapper>

            <InputWrapper>
                <Input value={state_password} onChange={(e) => set_password(e.target.value)} type='password' placeholder='密码' />
            </InputWrapper>

            <div style={{marginTop:'3rem'}}>
                {/* <input checked={state_terms_checked} onChange={() => handle_change({switch:'terms-check'})} type="checkbox" /> */}
                <span style={{marginLeft:'0.25rem', color:'#838383', cursor:'pointer'}} onClick={() => handle_click({switch:'terms-view'})}>用户协议</span>
            </div>
            <Button type='submit'>确定</Button>
        </Main>
    );
};

export default MainReactObj;

const Button = styled.button`
    border-radius: 40px;
    box-sizing: border-box;
    background-color: #BC7646;
    border: 0;
    font-size: 1.2rem;
    padding: 1rem 4rem 1rem 4rem;
    margin-top: 1rem;
    color: #FFFFFF;
`;

const Input = styled.input`
    height: 3rem;
    width: 90%;
    border-radius: 10px;
    border: 0;
    margin-top: 2vmax;
    // text-align: right;
    padding: 0.8rem 1rem;
    box-sizing: border-box;
    font-size: 1rem;
`;

const InputWrapper = styled.div`
    position: relative;

    span {
        position: absolute;
        top: 1.9rem;
        left: 2.3rem;
        color: #838383;
    }

    @media (min-width: 500px) {
        span {
            top: 2.5rem;
            left: 2.5rem;
        }    
    }
`;

const Logo = styled.img`
    display: block;
    padding-top: 7rem;
    margin-left: auto;
    margin-right: auto;
    width: 30%;
`;

const LogoSub = styled.img`
    display: block;
    padding-top: 1rem;
    margin-left: auto;
    margin-right: auto;
    width: 30%;
`;

const Main = styled.form`
    text-align: center;
    background-color: #F7F4F2;
    height: 100vh;

    @media (min-width: 500px) {
        max-width: 500px;
        margin: auto;
    }
`;
